import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
} from "react-share";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import { motion, AnimatePresence } from "framer-motion";

import { QueryFragments } from "@querys/queryFragments"; // eslint-disable-line

// Components
import { PageMeta } from "@components/pageMeta";
import Layout from "@components/layout";
import SimplePortableText from "@components/sanity/simplePortableText";
import { useSiteConfig } from "@querys/useSiteConfig";
import { Card } from "@components/card";
import { SanityImage } from "@components/sanity/sanityImage";

import FacebookIcon from "@svg/fb.svg";
import TwitterIcon from "@svg/tw.svg";
import EmailIcon from "@svg/em.svg";
import LinkIcon from "@svg/link.svg";

const OtherBlogs = ({ slug }) => {
  const { blogs } = useSiteConfig();
  const cards = blogs.edges
    .slice(0, 3)
    .filter((blog) => blog.node.slug.current !== slug);
  return cards.slice(0, 2).map((blog, i) => {
    const { featuredImage, slug, title, date, seo } = blog.node || {};
    return (
      <Card
        key={i}
        title={title}
        description={seo?.metaDesc}
        image={featuredImage}
        link={slug.current}
        linkText="read article"
        date={date}
        className="text-grey-900 bg-white flex flex-col justify-between"
        twoCards
      />
    );
  });
};

const BlogPost = ({ data: { post } }) => {
  const { slug, title, content, date, seo, featuredImage } = post || {};
  const { href } = useLocation();

  const [copyPopup, setCopyPopup] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setCopyPopup(false);
    }, 1000);
  }, [copyPopup]);

  return (
    <Layout>
      <PageMeta {...seo} />
      <div className="px-gutter md:mx-gutter my-32 md:my-64">
        <div className="text-center text-blue-600 flex flex-col gap-y-5 max-w-2xl mx-auto">
          <span className="blockH6 font-medium uppercase">{date}</span>
          <h1 className="blockH1">{title}</h1>
        </div>
        <div className="w-full h-full mt-20 md:mt-36">
          <SanityImage image={featuredImage} className="w-full h-full" />
        </div>
        <div className="mt-20 md:mt-36">
          <SimplePortableText text={content} />
        </div>

        <div className="flex gap-x-2 items-center mt-20 md:mt-32 relative w-[fit-content] md:w-full">
          <span className="mr-4 blockH7 font-medium">Share article:</span>
          <FacebookShareButton url={href}>
            <div className="w-10 h-10 bg-[#034EA215] flex items-center justify-center rounded-full">
              <FacebookIcon className="w-5 w-full" />
            </div>
          </FacebookShareButton>

          <TwitterShareButton url={href}>
            <div className="w-10 h-10 bg-[#034EA215] flex items-center justify-center rounded-full">
              <TwitterIcon className="w-5 w-full" />
            </div>
          </TwitterShareButton>

          <EmailShareButton url={href}>
            <div className="w-10 h-10 bg-[#034EA215] flex items-center justify-center rounded-full">
              <EmailIcon className="w-5 w-full" />
            </div>
          </EmailShareButton>

          <button
            onClick={() => {
              navigator.clipboard.writeText(href);
              setCopyPopup(true);
            }}
          >
            <div className="w-10 h-10 bg-[#034EA215] flex items-center justify-center rounded-full relative">
              <LinkIcon className="w-5 w-full" />
            </div>
          </button>
          <AnimatePresence exitBeforeEnter>
            {copyPopup && (
              <motion.div
                initial={{ opacity: 0, y: "-10px" }}
                animate={{
                  opacity: 1,
                  y: "-20px",
                  transition: { duration: 0.5 },
                }}
                exit={{ opacity: 0, transition: { duration: 0.5 } }}
                className="absolute md:relative -top-8 right-4 bg-grey-100 py-2 px-4 rounded-t-full rounded-l-full md:rounded-l-none md:rounded-t-full md:rounded-r-full blockH7"
              >
                Copied to clipboard
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>

      <div className="bg-grey-100 px-gutter pt-20 pb-24">
        <div className="flex flex-col md:flex-row md:justify-between w-full md:items-end text-blue-600">
          <span className="blockH1">OTHER NEWS</span>
          <Link to="/news" className="linkH6">
            View all
          </Link>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 gap-y-5 mt-16">
          <OtherBlogs slug={slug.current} />
        </div>
      </div>
    </Layout>
  );
};

export default BlogPost;

export const blogQuery = graphql`
  query blogPostQuery($slug: String!) {
    post: sanityBlogPost(slug: { current: { eq: $slug } }) {
      slug {
        current
      }
      title
      content: _rawPostBody(resolveReferences: { maxDepth: 10 })
      featuredImage {
        ...Image
      }
      date(locale: "NZ", formatString: "DD MMM YYYY")
      seo {
        ...SEO
      }
    }
  }
`;
