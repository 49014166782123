import React, { useState, useRef } from "react";
import { SanityImage } from "@components/sanity/sanityImage";
import { Link } from "gatsby";
import { AnimatePresence } from "framer-motion";
import cx from "classnames";
import { useInView } from "react-intersection-observer";

import SwitchLink from "@components/links/switchLink";
import BioPopup from "./popups/bioPopup";

export const Card = ({
  image,
  date,
  title,
  link,
  linkText,
  description,
  isSwitch,
  bioCard,
  label,
  teamMember,
  news = false,
  twoCards = false,
  ...rest
}) => {
  const [bioOpen, setBioOpen] = useState(false);
  const linkRef = useRef();
  const handleImageClick = () => {
    return linkRef.current.click();
  };

  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <>
      <div
        {...rest}
        ref={ref}
        className={cx("pending bg-white flex flex-col justify-between", {
          "not-in-view": !inView,
          "in-view": inView,
        })}
      >
        <div>
          <div className={`overflow-hidden relative aspect-w-16 aspect-h-10`}>
            {bioCard ? (
              <button
                onClick={() => {
                  setBioOpen(true);
                }}
              >
                {image && (
                  <SanityImage
                    className="hover:scale-105 transition-all duration-300 ease-in-out absolute top-0 left-0 w-full h-full object-cover"
                    image={image}
                  />
                )}
              </button>
            ) : (
              <div
                onClick={() => handleImageClick()}
                className="cursor-pointer"
              >
                {image && (
                  <SanityImage
                    className="hover:scale-105 transition-all duration-300 ease-in-out absolute top-0 left-0 w-full h-full object-cover"
                    image={image}
                  />
                )}
              </div>
            )}
          </div>
          <div className="bg-white pt-10 md:pt-8 px-8 flex flex-col font-medium">
            {(label || date) && (
              <span className="text-blue-600 mb-2 uppercase blockH8">
                {label ? label : date}
              </span>
            )}
            {title && (
              <span className={`text-blue-600 blockH5 font-display`}>
                {title}
              </span>
            )}
            {description && (
              <p
                className={`${
                  news ? "blockH7" : "blockH8"
                } font-display mt-2 text-grey-900`}
              >
                {description}
              </p>
            )}
          </div>
        </div>
        <div className="bg-white px-8 pb-10 md:pb-8 flex flex-col font-medium">
          {link && (
            <Link
              ref={linkRef}
              to={`/${link}`}
              className="text-blue-600 linkH2 mt-2"
            >
              {linkText}
            </Link>
          )}
          {isSwitch && (
            <SwitchLink
              switchRef={linkRef}
              className="text-blue-600 linkH2 mt-2"
              type={isSwitch?.link[0]?.type}
              {...isSwitch?.link[0]}
            />
          )}
          {bioCard && (
            <button
              onClick={() => setBioOpen(true)}
              className="linkH2 mt-2 text-blue-600 text-left"
            >
              READ BIO
            </button>
          )}
        </div>
      </div>
      <AnimatePresence exitBeforeEnter>
        {bioOpen && (
          <BioPopup
            key={bioOpen}
            setBioOpen={setBioOpen}
            className="w-full h-full fixed top-0 left-0 flex items-start md:items-center justify-center z-50 bg-white overflow-y-scroll"
            teamMember={teamMember}
          />
        )}
      </AnimatePresence>
    </>
  );
};
