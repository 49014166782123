import React from "react";
import { motion } from "framer-motion";

import { SanityImage } from "@components/sanity/sanityImage";
import SimplePortableText from "@components/sanity/simplePortableText";
import { useScrollFreeze } from "@hooks/useScrollFreeze";

import Close from "@svg/close-blue.svg";

const popupVars = {
  initial: {
    y: "100vh",
    transition: {
      duration: 0.5,
    },
  },
  animate: {
    y: 0,
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    y: "100vh",
    transition: {
      duration: 0.5,
    },
  },
};

const BioPopup = ({ teamMember, setBioOpen, ...rest }) => {
  const { bio, image, name, position } = teamMember;
  useScrollFreeze();

  return (
    <motion.div
      variants={popupVars}
      initial="initial"
      animate="animate"
      exit="exit"
      {...rest}
    >
      <div className="px-gutter flex flex-col md:flex-row gap-y-8 gap-x-20 my-24 md:my-0">
        <div className="w-full">{image && <SanityImage image={image} />}</div>
        <div className="w-full flex flex-col justify-center">
          <span className="blockH7 text-blue-600 font-medium">{position}</span>
          <h3 className="subH2 text-blue-600 my-2 md:my-5">{name}</h3>
          <SimplePortableText text={bio} />
        </div>
      </div>

      <button
        onClick={() => {
          setBioOpen(false);
        }}
        className="absolute right-gutter top-8 md:top-16"
      >
        <Close className="w-6 md:w-full" />
      </button>
    </motion.div>
  );
};

export default BioPopup;
